/* Buttons */
.btn {
	font-weight: bold;
	box-shadow: none;
	background-color: #353535;
	text-transform: initial;
	line-height: 45px;
	height: 45px;
	font-size: 18px;
	border-radius: 5px;
	color: #fff;
}

i.left {
	margin-right: 7px;
}

.btn:hover, .btn-large:hover {
	background-color: #9e9e9e;
	box-shadow: none;
	color: #fff;
}

.btn:focus, .btn-large:focus, .btn-floating:focus {
	background-color: #353535;
}

.btn-border,
.btn-border-big,
.btn-border-small {
	letter-spacing: -0.2px;
	text-decoration: none !important;
	font-size: 16px;
	line-height: 35px;
	height: 39px;
	transition: color .4s ease 0s;
	color: #1BA1DB !important;
	margin: calc(-1 * 2px) 2px 0;
	position: relative;
	border-radius: 31px;
	background: #fff;
	border: 2px solid #1ba1db;
	font-weight: normal;
	padding: 0 60px;
}

.btn-border-big {
	line-height: 46px !important;
	height: 50px !important;
}

.btn-border-small {
	padding: 0 28px;
	line-height: 26px !important;
	height: 30px !important;
}


.btn-border:hover,
.btn-border-big:hover,
.btn-border-small:hover {
	background-color: #343434;
	border: 2px solid #343434;
	color: #fff !important;
}

.btn-border:focus,
.btn-border-big:focus,
.btn-border-small:focus {
	border: 2px solid #343434;
}

.btn-border-white {
	text-decoration: none !important;
	font-size: 16px;
	line-height: 35px;
	height: 39px;
	transition: color .4s ease 0s;
	color: #ffffff !important;
	margin: calc(-1 * 2px) 2px 0;
	position: relative;
	border-radius: 31px;
	background: none;
	border: 2px solid #fff;
	font-weight: normal;
	padding: 0;
	width: 100%;
}

.btn-border-white:hover {
	background-color: #343434;
	border: 2px solid #343434;
	color: #fff;
}

.btn-border-white:focus {
	border: 2px solid #343434;
}


.btn-subscribe {
	width:100%;
	border-radius:0;
	background: #fff;
	color:#000;
	font-size: 13px;
	font-weight: 300;
	height: 37px;
	line-height: 37px;
}

/* Scroll top button */
.btn-scroll-top {
	display: none;
	position: fixed;
	top: 0;
	right: 15%;
	padding: 10px;
	border: 2px solid white;
	background-color: rgba(255, 255, 255, 0.44);
	stroke: #C9B691;
	color: #605E5E;
	z-index: 1000;
}

.btn-scroll-top p {
	margin: .25rem;
}

.btn-scroll-top:hover {
	color: #BC9B5D;
	stroke: #DE5021;
}

.btn-scroll-top:active {
	border: 2px solid white;
	background-color: rgba(100%, 100%, 100%, 0.44);
}