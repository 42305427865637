@media only screen and (min-width: 601px) {
	nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
		height: 115px;
		line-height: 115px;
	}

	nav a.sidenav-trigger {
		display: none;
	}

	.b-footer-contacts {
		float: right;
		text-align: right;
		margin-top: 0;
	}

	.parallax-window {
		min-height: 570px;
	}

	.main-small-height-parallax {
		min-height: 506px;
	}

	.parallax-bg {
		min-height: 410px !important;
	}

	.contest {
		margin-top: 215px;
	}

	.program-goal-parallax-mobile .parallax-content-desc {
		font-size: 23px;
		padding: 5px 20px 0 50px;
	}
}

@media only screen and (min-width: 993px) {
	.container {
		width: 80%;
	}
}

@media only screen and (max-width: 762px) {
	.b-banner {
		font-size: 35px;
	}

	.b-banner-title-right {
		text-align: left;
	}

	nav .brand-logo {
		font-size: 4.8rem;
	}

	.program-goal-parallax {
		display: none;
	}

	.program-goal-parallax-mobile {
		display: block;
	}
}

@media only screen and (max-width: 420px) {
	.b-banner {
		font-size: 28px;
	}

	nav .brand-logo {
		font-size: 4.8rem;
	}
}

