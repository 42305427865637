/* Modals */
.modal {
	color: #000;
	box-shadow: none;
	width:  61%;
}

@media only screen and (max-width: 992px) {
	.modal {
		width: 80%;
	}
}

@media only screen and (max-width: 601px) {
	.modal {
		width: 100%;
	}
}

.modal p {
	color: #000;
	font-size: 14px;
}

.modal-overlay img {
	position: fixed;
	right: 20px;
	top: 20px;
	cursor: pointer;
}

.modal-text {
	margin-top: 55px;
}

.modal-text p {
	text-align: justify;
}

#modal__feedback,
#modal__policy {
	background: rgba(255, 255, 255, 0);
	color: #fff;
}
#modal__feedback input{
	border-bottom: 1px solid #fff;
	-webkit-box-shadow: 0 1px 0 0 #fff;
	box-shadow: 0 1px 0 0 #fff;
}
.modal__close-icon {
	position: absolute;
	right: 30px;
	top: 26px;
	cursor: pointer;
}
.modal__close-icon svg {
	width:20px;
}
.modal__close-icon .icon-close {
	fill: #fff;
}
.modal-close-order {
	position: absolute;
	top: 120px;
	right: 20px;
	color: #fff;
	cursor: pointer;
	font-size: 30px;
}

.modal-overlay{
	top: -100px;
	position: fixed;
}