/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700&subset=cyrillic');
*/
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700&subset=cyrillic');
html, body {
	height: 100%;
	font-size:13px;
}

body {
	font-family: 'Gilroy', sans-serif;
	background: #fff;
}

a {
	transition: all .3s ease;
}

a:hover {

}
b, strong {
	font-weight: 700;
}

.b-content a {
	color: #605E5E;
	text-decoration: none;
}

.b-content a:hover {
	text-decoration: underline;
}

p {
	font-size: 13px;
	line-height: 19px;
}

h5 {
	font-size: 20px;
	font-weight: bold;
	color: #605E5E;
}

.b-content ul {
	padding-left: 1.3em;
	padding-right: 0;
	margin-left: 0.5em;
	margin-right: 0;
	line-height: normal;
	letter-spacing: normal;
}

.b-content li {
	font-size: 14px;
	list-style-type: disc !important;
}

.container {
	margin: 0 auto;
	max-width: 980px;
	width: 90%;
}

.mb30 {
	margin-bottom: 30px !important;
}

/* header */
nav {
	background: none;
	box-shadow: none;
	width: 100%;
	height: 115px;
	line-height: 115px;
}

nav a {
	color: #7f7f7f;
}

nav ul li {
	padding: 0 2px;
}

nav ul li.active {
	background: none;
}

nav ul a {
	font-size: 17px;
	font-weight: bold;
	padding: 0;
	color: rgba(255, 255, 255, 0.54);
	line-height: 74px;
}

nav ul a:hover {
	transition: all .3s ease;
	background: none;
	color: #fff;
}

nav .brand-logo {
	padding-right: 45px !important;
	font-size: 6rem;
}

nav .brand-logo img {
	width: 100%;
}

nav i.material-icons {
	font-size: 36px;
}

nav .sidenav-trigger {
	margin: 0;
}

nav .sidenav-trigger i {
	height: 115px;
	line-height: 115px;
}

.social_icon {
	fill: transparent !important;
	vertical-align: middle;
}

.social_icon .ico {
	transition: all .3s ease;
	fill: #7f7f7f;
}

.social_icon:hover .ico {
	fill: hsla(0, 0%, 50%, .7) !important;
}

.b-banner {
	background: #fff;
	padding: 60px 0 20px;
	font-size: 41px;
	color: #605E5E;
	letter-spacing: -1px;
	font-weight: bold;
}

.b-banner-title-left {

}

.b-banner-title-right {
	text-align: right;
}

.fix-img {
	height: 320px;
	overflow: hidden;
}

.fix-img img {
	width: 100%;
}

/* top menu */
.b-top-menu {
	width: inherit;
	background: #fff;
	border-top: 1px solid rgba(204, 204, 204, 0.43);
}

.b-top-menu ul {
	margin: 0;
	display: table;
	width: 100%;
}

.b-top-menu ul li {
	display: table-cell;
	position: relative;
}

.b-top-menu ul li a {
	font-family:'Gilroy' !important;
	text-align: center;
	line-height: 50px;
	font-size: 17px;
	font-weight: 300;
	color: #605E5E;
	width: 100%;
	display: block;
}

.b-top-menu ul li a:hover {
	color: #000;
}

.b-top-menu ul > li.active > a {
	color: #1BA1DB;
}

.b-top-menu ul li ul.child {
	display: none;
	position: absolute;
	left: 5px;
	top: 48px;
	z-index: 1;
	background: #fff;
	/* box-shadow: 0 5px 9px -1px rgba(0, 0, 0, 0.5); */
}

.b-top-menu li.parent:hover ul.child {
	display: inline;
}

.b-top-menu ul li ul.child li {
	display: block;
}

.b-top-menu ul li ul.child li a {
	display: block;
	font-size: 17px;
	padding: 7px 10px;
	margin: 0;
	line-height: inherit;
}

.sidenav ul.child li {
	padding: 0 15px;
}

/* Footer */


.footer {
	color: #fff;
	background: #2f2e2e;
	padding: 40px 0;
}

.footer-parallax {
	/* margin-bottom: 40px; */
}

.b-footer-subscribe {

}

.b-subscribe-input {
	margin-bottom: 10px;
}

.subscribe-desc {
	font-size: 14px;
}

.subscribe {

}

.b-subscribe-btn {

}

.b-footer-contacts {
	float: none;
	text-align: center;
	margin-top: 50px;
}

.b-footer-social {
	margin: 15px 0;
}

.b-footer-info {
	font-size: 13px;
}

.b-footer-contacts a {
	color: #fff;
}

.social-item {
	display: inline-block;
	font-size: 20px;
	margin-left: 15px;
}

/* Parallax*/
.parallax-window {
	min-height: 230px;
	position: relative;
}

.program-main-slider img {
	margin-top: 300px;
}

.parallax-bg {
	background: rgba(0, 0, 0, 0.45);
}

.parallax-blue-bg {
	background: rgba(4,76,164,0.05);
}

.parallax-content-pp {
	box-sizing: border-box;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	margin-bottom: 0;
}

.parallax-content-pp .cols {
	height:100%;
	padding-top: 135px;
}
.parallax-content-title {
	color: #fff;
	text-align: center;
	font-size: 32px;
	font-weight: bold;
	font-family: 'Gilroy', Arial !important;
}
.parallax-content-desc {
	color: #fff;
	font-family: 'Gilroy', Arial !important;
	font-size: 23px;
	max-width: 656px;
	padding: 5px 20px 0 50px;
	text-align: justify;
}

.program-goal-parallax {
	display: block;
	min-height: 475px;
}

.program-goal-parallax-mobile {
	display: none;
	height: 500px;
}

.program-goal-parallax-mobile .b-top-cols {
	height: 30%;
}

.program-goal-parallax-mobile .b-bottom-cols {
	height: 70%;
	background-image: url("/local/templates/pp/img/pp01.jpg");
	background-size: cover;
	padding: 0;
}

.program-goal-parallax-mobile .content-desc-wrapper {
	width: 100%;
	height: 100%;
	background: rgba(4, 76, 164, 0.51);
	padding: 30px 0.75rem;
}

.program-goal-parallax-mobile .parallax-content-title {
	margin-top: 50px;
}

.program-goal-parallax-mobile .parallax-content-desc {
	font-size: 17px;
	padding: 0;
}

.b-top-cols, .b-left-cols {
	background: #ff6168;
}

.b-right-cols {
background: rgba(4, 76, 164, 0.51);
}

.parallax-content-text {
	color: #fff;
	font-size: 19px;
	font-weight: bold;
}

.parallax-slider img {
	width: 100%;
}

.b-content {
	color: #605E5E;
	padding: 35px 0;
}

.b-content-title {
	font-size: 28px;
	font-weight: 600;
	letter-spacing: -1px;
	margin: 0;
	color: #605E5E;
	margin-bottom: 10px;
}

.b-content-subtitle {
	font-size: 22px;
	margin: 0;
	color: #1BA1DB;
	margin-bottom: 25px;
}

.b-item-list {
	margin: 30px 0;
	color: #605E5E;
}

.b-item-list-text {
	margin-bottom: 30px;
}

.b-item-list-subtitle {
	font-size: 18px;
	font-weight: 600;
}

/* helpers */

.h-text-overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	box-sizing: border-box;
	max-width: 100%;
	display: inline-block;
}

/* main news list */
.main-news-list {

}

.main-news-list-item {
	margin-bottom: 50px;
	position: relative;
}

.main-news-list-item .row {
	margin-left: -11px;
	margin-right: -11px;
}

.main-news-list-item__title {
	font-family:'Gilroy' !important;
	font-size: 18px;
	font-weight: bold;
	color: #605E5E;
}

.main-news-list-item__title a {
	text-decoration: none;
}
.main-news-list-item__title a:hover {
	text-decoration: underline;
}

.main-news-list-item__anons {
	margin-top: 17px;
}

.left-aligned-button {
	left: 0;
}

.main-news-list-item__link {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.main-news-list-item__picture {
	margin-top: 20px;
}

.main-news-list-item__picture img {
	width: 100%;
}

.b-news-date-time {
	width:100%;
}

/* MATERIALIZE BITRIX FIX
 ====================== */
.bx-core-window select {
	display: block !important;
}

.bx-core-window {

}

.bx-core-window [type="checkbox"] + label:before, .bx-core-window [type="checkbox"]:not
	 (.filled-in ) + label:after, .bx-core-window [type="radio"] + label:before,
.bx-core-window [type="radio"] + label:after {
	content: none;
}

.bx-core-window [type="checkbox"] + label {
	padding-left: inherit;
}

.bx-core-window [type="radio"]:not (:checked ), .bx-core-window [type="radio"]:checked {
	position: static;
	left: 0;
	opacity: 1;
}

.bx-core-window input:not ([type] ), .bx-core-window input[type=text],
.bx-core-window input[type=password], .bx-core-window input[type=email],
.bx-core-window input[type=url], .bx-core-window input[type=time],
.bx-core-window input[type=date], .bx-core-window input[type=datetime],
.bx-core-window input[type=datetime-local], .bx-core-window input[type=tel],
.bx-core-window input[type=number], .bx-core-window input[type=search],
.bx-core-window textarea.materialize-textarea {
	width: inherit;
}

.bx-core-adm-dialog select, .bx-core-adm-dialog input, .bx-core-adm-dialog textarea {
	outline: 0;
	font-size: 13px;
	opacity: 1 !important;
}

/* contacts */
.static-image {
	height: 487px;
	overflow: hidden;
}

.static-image img {
	width: 100%;
}

.b-contacts {
	margin-top: 30px;
}

.b-contacts-block {
	margin-top: 20px;
}

.b-contacts-block h5 {
	margin-bottom: 0;
	font-size: 18px;
}

/* Contest */
.contest-title {
	font-size: 55px;
	font-weight: bold;
	color: #fff;
	margin: 20px;
}

.contest-list {

}

.contest-list-item {
	background: rgba(255,255,255,.4);
	color: #fff;
	padding: 10px 10px 30px;
	margin-bottom: 30px;
}

.contest-list-item-num {
	font-size: 103px;
	font-weight: bold;
	position: relative;
}

.contest-list-item-num::after {
	content: '';
	display: inline-block;
	position: absolute;
	width: 60px;
	height: 3px;
	background: #fff;
	bottom: 0;
	left: 50%;
	margin-left: -30px;
}

.contest-list-item-text {
	font-size: 18px;
	line-height: 22px;
	font-weight: bold;
	min-height:255px;
	margin: 20px 0;
}

.content-list-item-btn {
	padding:0 15px;
}

/* Popup for contact's map */
.popup-tip-anchor {
	height: 0;
	position: absolute;
	width: 200px;
}

.popup-bubble-anchor {
	position: absolute;
	width: 100%;
	bottom: 60px;
	left: 0;
}

.popup-bubble-anchor::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, 0);
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 8px solid white;
}

.popup-bubble-content {
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, -100%);
	background-color: white;
	padding: 10px;
	border-radius: 5px;
	font-family: sans-serif;
	overflow-y: auto;
	max-height: 60px;
	box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
}