/* Tabs */

.tabs {
	height: 40px;
	background: none;
}

.tabs .indicator {
	height: 1px;
	background-color: #fff;
}
.tabs .tab {
	text-transform: none;
	margin: 0 7px;
}

.tabs .tab a {
	line-height: 31px;
	padding: 0 5px;
	background: #8b663c;
	border-radius: 0;
	height: 30px;
	text-transform: uppercase;
	font-size: 14px;
	width: 165px;
	color: #fff;
}

.tabs .tab a:hover, .tabs .tab a.active {
	background-color: #f3eeeb;
	color: #000;
	border: 1px solid #8b663c;
}

.b-tabs__buttons {
	margin-bottom: 30px
}

@media only screen and (max-width: 993px) {
	.b-tabs__buttons .tabs {
		display: block;
		height: auto;
		margin: 0 auto;
	}
	.b-tabs__buttons .tabs .tab{
		display: block;
		margin-top: 10px;
		margin: 0 auto;
		width: 175px;
	}
}