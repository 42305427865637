@font-face {
    font-family: 'Gilroy';
    src: local('☞Gilroy-SemiBoldItalic'), local('Gilroy-SemiBoldItalic'),
        url('Gilroy-SemiBoldItalic.woff2') format('woff2'),
        url('Gilroy-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('☞Gilroy-SemiBold'), local('Gilroy-SemiBold'),
        url('Gilroy-SemiBold.woff2') format('woff2'),
        url('Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('☞Gilroy-Medium'), local('Gilroy-Medium'),
        url('Gilroy-Medium.woff2') format('woff2'),
        url('Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('☞Gilroy-Bold'), local('Gilroy-Bold'),
        url('Gilroy-Bold.woff2') format('woff2'),
        url('Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('☞Gilroy-Black'), local('Gilroy-Black'),
        url('Gilroy-Black.woff2') format('woff2'),
        url('Gilroy-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('☞Gilroy-Regular'), local('Gilroy-Regular'),
        url('Gilroy-Regular.woff2') format('woff2'),
        url('Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-RegularItalic';
    src: local('☞Gilroy-RegularItalic'), local('Gilroy-RegularItalic'),
        url('Gilroy-RegularItalic.woff2') format('woff2'),
        url('Gilroy-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('☞Gilroy-MediumItalic'), local('Gilroy-MediumItalic'),
        url('Gilroy-MediumItalic.woff2') format('woff2'),
        url('Gilroy-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('Gilroy-Light.woff2') format('woff2'),
        url('Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
        url('Gilroy-ExtraBold.woff2') format('woff2'),
        url('Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

