.widgets {
	margin-top: 15px;
}

.b-widgets-panel {

}

.b-widgets-header {
	line-height: 60px;
}

.header-title {
	display: inline-block;
	margin: 0;
	padding-top: 15px;
}

.header-buttons {
	display: inline-block;
	float: right;
}

.b-widgets-wrap {

}

.widget {
	padding: 15px 20px;
	border-radius: 5px;
	box-shadow: 2px 4px 23px -11px #000;
}

/* Датчики */
.widget-type-sensor {

}

.widget-icon {
	font-size: 22px;
	display: block;
}

.widget-name {
	font-weight: bold;
	font-size: 17px;
	margin: 5px 0 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	box-sizing: border-box;
	max-width: 100%;
	display: block;
}

.widget-value {

}

.value {

}

.value-name {

}

/* Devices */
.widget-type-device {
	cursor: pointer;
	position: relative;
}
.widget-type-device::before {
	content: "\f013";
	font-family: Font Awesome\ 5 Free;
	font-weight: 900;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 6px;
	top: 10px;
	color: #7b7b7b;
	display: none;
}
.widget-type-device:hover {
	box-shadow: 2px 11px 43px -4px rgba(0, 0, 0, 0.70);
}
.widget-type-device:hover::before {
	display: inline-block;
}
/* Notification */
.widget-type-notifications {
	min-height: 420px;
}

.notification-item {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.notification-header {
	line-height: 28px;
	padding-left: 20px;
	position: relative;
}

.notification-icon {
	position: absolute;
	left: 0;
	top: 7px;
}

.notification-title {
	font-weight: bold;
	font-size: 16px;
	display: inline-block;
}

.notification-time {
	float: right;
	font-size: 13px;
}

.notification-message {
	padding-left: 20px;
}

/* Schame */

.widget-type-scheme {

}

.scheme-img {
	width: 100%;
}

.scheme-widgets-wrap {

}