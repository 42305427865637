/* Forms
 =================== */
.field {
	margin-bottom: 25px;
}

input.browser-default {
	border: none;
	border-radius: 0;
	background: rgba(255, 255, 255, 0.29);
	padding: 0 13px;
	box-sizing: border-box;
	margin: 0;
	height: 35px;
	width: 100%;
	font-size: 13px;
	color: #ffffff;
}

input.browser-default::-webkit-input-placeholder,
textarea.browser-default::-webkit-input-placeholder {
	color: #fff;
}

input.browser-default::-moz-placeholder,
textarea.browser-default::-moz-placeholder {
	color: #fff;
}

input.browser-default:-moz-placeholder,
textarea.browser-default:-moz-placeholder {
	color: #fff;
}

input.browser-default:-ms-input-placeholder,
textarea.browser-default:-ms-input-placeholder {
	color: fff;
}

input.browser-default:focus {
	box-shadow: none !important;
	border-bottom: 1px solid #eaeaea !important;
}

[type="checkbox"] + label {
	padding-left: 45px;
	height: 30px;
	line-height: 30px;
	color: #fff;
}

[type="checkbox"].filled-in:checked + label:before {
	width: 12px;
	height: 21px;
	border-right: 3px solid #008fc7;
	border-bottom: 3px solid #008fc7;
}

[type="checkbox"].filled-in:checked + label:after,
[type="checkbox"].filled-in:not(:checked) + label:after,
[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
	top: 0;
	width: 30px;
	height: 30px;
	z-index: 0;
	border-radius: 5px;
	border: 2px solid #fff;
	background-color: #fff;
}

:focus::-webkit-input-placeholder {
	color: transparent;
	transition: all .3s ease;
}

:focus::-moz-placeholder {
	color: transparent;
	transition: all .3s ease;
}

:focus:-moz-placeholder {
	color: transparent;
	transition: all .3s ease;
}

:focus:-ms-input-placeholder {
	color: transparent;
	transition: all .3s ease;
}

textarea.browser-default {
	resize: none;
	border: 1px solid #fff;
	background: rgba(255, 255, 255, 0.29);
	color: #ffffff;
	border-radius: 2px;
	padding: 8px 13px;
	box-sizing: border-box;
	height: 65px;
	margin: 0;
	font-size: 13px;
	width: 100%;
}

input.input-jointly {
	display: inline-block;
	width: 60% !important;
	box-sizing: border-box !important;
	border: none !important;
	background-color: #3b3b3b !important;
	height: 55px !important;
	padding: 0 15px !important;
	color: #ccc;
}

@media only screen and (min-width: 601px) {
	input.input-jointly {
		width: 80% !important;
	}
}

/* Select */
.select-wrapper input.select-dropdown {
	background-color: #fff;
	height: 55px;
	line-height: 55px;
	font-size: 13px;
	padding: 0 15px;
	box-sizing: border-box;
}

.dropdown-content li > a, .dropdown-content li > span {
	font-size: 13px;
	color: #555;
}

.select-wrapper span.caret {
	right: 18px;
	font-size: 17px;
	z-index: 1;
	color: #999;
	pointer-events: none;
}

.pp_form {
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: transparent;
	border: 1px solid rgba(160,160,159,1)!important;
	color: #605E5E;
	display: block;
	padding: 5px!important;
	width: 100%!important;
	border-radius: 30px!important;
	box-sizing: content-box !important;
}

input.pp_form {
	height: 19px !important;
}

textarea.pp_form {
	height:130px !important;
	resize: none !important;
}